<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
        persistent
        v-if="item"
    >
      <div class="video-order-popup-wrapper" style="max-height: 90%; overflow-y: auto" v-if="!isSent">
        <div class="video-order-header">
          <h1 class="video-order-header__title">{{ popupTitle }}</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div v-if="status !== 'declined'">
          <div class="d-flex justify-space-between align-center mt-10">
            <div class="d-flex">
              <img loading="lazy" :src="item.user.avatar" alt="Ukrmusic" v-if="item && item.user.avatar" class="item-image mr-5">
              <img src="../../assets/img/default-avatar.png" alt="Ukrmusic" class="item-image mr-5" v-else>
              <div class="d-flex flex-column justify-space-between">
                <div class="nickName">{{ item.user.nickName }}</div>
                <div class="date open-sans">{{ item.orderedAt }}</div>
              </div>
            </div>
            <div class="video-price">{{ `+ ₴${item.money.amount}.00` }}</div>
          </div>
          <div class="user-desire mt-5">
            <h2 class="input-title">User desire</h2>
            <textarea name="" id="user-desire" rows="3" class="input w-full mt-4"
                      :value="item.fanDesire" placeholder="Text" readonly></textarea>
          </div>
        </div>
        <div v-if="status === 'declined'" class="mt-10">
          <p class="declined-text">You sure you want decline this offer?</p>
          <button class="btn-yellow w-full mt-8" @click="declineOffer">Decline</button>
        </div>
        <div class="d-flex mt-10" v-if="!status">
          <ButtonBlue class="mr-5 w-full" @clickEvent="status = 'declined'">
            <span>Decline</span>
          </ButtonBlue>
          <button class="btn-yellow w-full" @click="status = 'accepted'">Accept</button>
        </div>
        <form class="video-order-wrapper mt-5" enctype='multipart/form-data' @submit.prevent="submitAction"
              v-if="status === 'accepted'">
          <div v-show="!file">
            <DragDropFileUpload ref="DragDropFile" @handleInput="handleVideo"
                                :text="uploadSongText" :title="uploadSongTitle"
                                :supportedFile="'video/*'" :inputId="'video-offer'"/>
          </div>
          <div v-if="file">
            <div class="input w-full d-flex justify-space-between align-center">
              <div class="d-flex align-center">
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="mr-2">
                  <path
                      d="M22 8.22917C22 8.22917 22 23.3743 22 24.2331C22 25.0919 5.09794 25.4073 5.09614 24.2331C5.09433 23.0589 4.96956 11.5102 5.007 2.74136C5.01163 1.65798 13.8876 2.08153 15.0176 2.08165M22 8.22917C22 7.4588 20.4476 5.21492 19.0994 4.19496C17.6955 3.13283 16.1476 2.08177 15.0176 2.08165M22 8.22917C21.6468 8.69825 21.4688 9.1875 19.3438 9.42124C17.2188 9.65498 15.0176 10.0021 15.0176 9.42124C15.0176 8.84038 15.024 6.44522 15.0176 6.22653C14.965 4.44042 15.0176 3.86818 15.0176 2.08165"
                      stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <span>{{ file.name }}</span>
              </div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
                   @click="removeFile" style="cursor: pointer" id="video-offer-remove-file">
                <path d="M18 6.5C13.7552 9.87699 10.9401 13.0617 6 18.5" stroke="#0057B7" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 6.5C8.23219 9.75226 10.383 12.1003 18 18.5" stroke="#0057B7" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
          <button class="btn-yellow w-full mt-10" type="submit" v-if="file">Send</button>
          <div class="btn-yellow disabled btn-no-hover w-full mt-10" v-else>Send</div>
        </form>
      </div>
      <div class="video-order-popup-wrapper" style="max-height: 90%; overflow-y: auto" v-else>
        <p class="sent-complete-text">We are happy for <span style="font-weight: 700;">{{ item.user.nickName }}</span>. Video congratulation was be sended</p>
        <button class="btn-yellow w-full mt-8" @click="closePopup">Done</button>
      </div>
      <div class="preloader-wrap" v-if="preloaderFlag">
        <PreLoader />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ButtonBlue from "@/components/elements/ButtonBlue.vue";
import DragDropFileUpload from "@/components/DragDropFileUpload.vue";
import PreLoader from "@/components/elements/Preloader.vue";

export default {
  name: "VideoOfferForArtistPopup",
  components: {PreLoader, DragDropFileUpload, ButtonBlue},
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      formData: new FormData(),
      status: '',
      errorMessage: '',
      uploadSongText: 'Supported file type mp3, waw, mp4. File must not exceed 200 MB',
      uploadSongTitle: 'Upload file',
      file: null,
      preloaderFlag: false,
      isSent: false
    }
  },
  computed: {
    popupTitle() {
      return this.status === 'accepted' ? 'Record video congratulation' : this.status === 'declined' ? 'Decline video congratulation' : 'View offer'
    }
  },
  methods: {
    ...mapActions({
      uploadVideoCongratulations: 'video/uploadVideoCongratulationsAction',
      declineCongratulationOrder: 'video/declineVideoCongratulationsAction'
    }),
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
      this.resetForm()
    },
    async submitAction() {
      this.preloaderFlag = true
      let offerId = this.item.id
      let payload = {
        offerId,
        body: this.formData
      }
      try {
        this.errorMessage = ''
        await this.uploadVideoCongratulations(payload)
        this.resetForm()
        this.isSent = true
      } catch (error) {
        this.preloaderFlag = false
        this.errorMessage = error.response.data.errors[0].message
      }

    },
    async declineOffer() {
      await this.declineCongratulationOrder(this.item.id)
      this.closePopup()
    },
    handleVideo(files) {
      this.file = files[0].file
      this.formData.set('video', this.file)
    },
    removeFile() {
      this.file = null
      this.$refs.DragDropFile.clearResults()
      this.formData.delete('video')
    },
    resetForm() {
      this.formData = new FormData()
      this.file = null
      this.status = ''
      this.preloaderFlag = false
    }
  }
}
</script>

<style scoped>
.video-order-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.video-order-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.video-order-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.item-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.video-price {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #40C800;
}


.declined-text, .sent-complete-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primary-blue);
}

.nickName {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}
</style>