<template>
  <div class="d-flex flex-column">
    <div class="wrap">
      <iframe
          src="https://cdn.djookyx.com/2023-06-16-10-34-23-9772f916-3ec4-433d-b58f-410759fe82f2-FULL.pdf"
          height="3600px"
          width="100%"
          frameborder="0"
          allowfullscreen
          style="border:none"
      ></iframe>
    </div>
  </div>
</template>

<script>

export default {
  name: "TestTest",
  data() {
    return {

    }
  },
  methods: {
    check(data) {
      console.log(data)
    }
  },
  components: {}
}
</script>

<style scoped>
.comments ul {
  padding-left: 16px;
  margin: 6px 0;
}

.wrap {
  display: flex;
  max-height: 800px;
  overflow: auto;
}
</style>