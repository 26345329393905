import instance from "../api/instance";


export const payment = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async purchase(context, body) {
            let res = (await instance.post(`/api/v1/payments/purchases`, body)).data.body
            return res
        },
    }
}