<template>
  <div class="d-flex">
    <router-link :to="{path: `/profile/${owner.nickName}`}">
      <img loading="lazy" v-if="owner.avatar && ownerAvatar" :src="owner.avatar" alt="Ukrmusic" class="rounded-circle mr-5"
           style="width: 60px; height: 60px" @error="ownerAvatar = false">
      <img v-else src="../../assets/img/default-avatar.png" alt="Ukrmusic" class="rounded-circle mr-5"
           style="width: 60px; height: 60px">
    </router-link>
    <div class="d-flex flex-column justify-center">
      <div class="nick-name mb-2">{{ owner.nickName }}</div>
      <div class="created-at">{{ createdAt }}</div>
    </div>
    <div class="ml-auto d-flex align-center" v-if="isPremium">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
        <path d="M16.4283 9.26503C17.1829 8.99196 23.295 8.39145 22.4132 9.26503C21.9528 9.72123 18.0044 12.7594 18.2461 13.8415C18.4879 14.9236 19.8832 18.0904 20.8974 19.8159C21.347 20.5808 21.8724 22.5588 20.8974 22.2205C19.808 21.8424 14.755 16.6905 12.7138 16.6905C11.9531 16.6905 10.8949 17.295 10.3736 17.6585C8.42105 19.02 3.01055 23.6792 3.66191 22.2205C4.31327 20.7617 7.17328 14.696 7.51926 13.8415C7.86523 12.987 3.09978 11.2201 1.69031 10.1386C0.280848 9.05711 7.08695 9.39973 9.42072 9.26503M16.3492 9.04979C14.84 4.94421 13.6003 1.45014 13.1407 1.50054C12.4997 1.57082 11.054 5.2177 9.445 9.20218" stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
      </svg>
      <p class="prem-post-text">Premium post</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowPostHeaderComponent",
  data() {
    return {
      ownerAvatar: true
    }
  },
  props: {
    owner: {
      type: Object,
      required: false
    },
    createdAt: {
      type: String,
      required: false
    },
    isPremium: {
      type: Boolean,
      required: true
    }
  },
}
</script>

<style scoped>
.nick-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.created-at {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
  opacity: 0.5;
}

.prem-post-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
}
</style>